<template>
  <div class="list">
    <div class="tip">
      <p>温馨提示：请在投保前阅读并确认以下内容</p>
    </div>
    <div class="msg">
      <p>【承保公司】</p>
      <p>
        第一条、本保险产品由乐荐保保险代理有限公司销售，中国人民健康保险股份有限公司承保，借助线上投保工具面向北京地区销售。
      </p>
      <p>【适用条款】</p>
      <p>
        第二条、适用条款：《中国人民健康保险股份有限公司人保健康预防接种异常反应医疗意外保险条款》（人保健康人保健康[2022]医疗意外保险050号）。
      </p>
      <p>【保单形式】</p>
      <p>
        第三条、保单形式：线上投保为您提供电子保单，根据《中华人民共和国民法典》第四百六十九条规定，以电子数据交换、电子邮件等方式能够有形地表现所载内容，并可以随时调取查用的数据电文，视为书面形式。电子保单与纸质保单具有同等法律效力。电子保单将通过电子邮件发送至您邮箱，或您也可以通过关注“人保健康生活”微信公众号，自助查询保单信息，如您需要纸质保单及发票，请拨打人保健康专属疫苗保险客服电话010-80882173进行申请，配送方式为快递到付。
      </p>
      <p>【偿付能力】</p>
      <p>
        第四条、本公司最近季度(2023年3季度)的综合偿付能力充足率为249.86%最近一期 (2023年3季度)的风险综合评级为BB类，偿付能力充足率和反险综合评级均达到监管要求。
      </p>
      <p>【产品介绍】</p>
      <p>
        第五条、被保险人年龄不受限制，计划接种疫苗的个人均可作为被保险人。若投保时被保险人出生未满30天(含），且尚未发生异常反应，追溯期为该被保险人出生后接种首针疫苗之时或缴费成功之时（以先发生者为准）至本合同生效日零时。若投保时被保险人出生已满30天，追溯期为缴费成功之时至本合同生效日零时。
      </p>
      <p>
        第六条、本合同的保障计划由投保人在投保时与本公司约定并在保险单上载明，保障计划见保险条款附表。投保时投保人仅可选择投保单一计划。
      </p>
      <p>
        第七条、若投保时被保险人出生已满30天，追溯期为缴费成功之时至本合同生效日零时。
      </p>
      <p>
        第八条、保障说明：在保险期间或本合同约定的追溯期内，被保险人在实施接种本合同保障针次的疫苗过程中或者实施接种后出现疑似预防接种异常反应，经卫生健康主管部门认可的机构按法定的调查诊断及鉴定程序认定为由本合同保障针次的疫苗导致预防接种异常反应或不排除预防接种异常反应，而造成死亡、残疾或支出的医疗费用，本公司按照约定承担保险责任。
      </p>
      <p>
        第九条、预防接种异常反应：根据《中华人民共和国疫苗管理法》，预防接种异常反应是指合格的疫苗在实施规范接种过程中或者实施规范接种后造成受种者机体组织器官、功能损害，相关各方均无过错的药品不良反应。
      </p>
      <p>下列情形不属于预防接种异常反应：</p>
      <p>1)因疫苗本身特性引起的接种后一般反应；</p>
      <p>2)因疫苗质量问题给受种者造成的损害；</p>
      <p>
        3)因接种单位违反预防接种工作规范、免疫程序、疫苗使用指导原则、接种方案给受种者造成的损害；
      </p>
      <p>4)受种者在接种时正处于某种疾病的潜伏期或者前驱期，接种后偶合发病；</p>
      <p>
        5)受种者有疫苗说明书规定的接种禁忌，在接种前受种者或者其监护人未如实提供受种者的健康状况和接种禁忌等情况，接种后受种者原有疾病急性复发或者病情加重；
      </p>
      <p>6)因心理因素发生的个体或者群体的心因性反应。</p>
      <p>
        第十条、异常反应致被保险人残疾的，保险人按照国家现行有效的《医疗事故分级标准》（试行）确定的伤残等级与本保险条款2.3.2中对应的损害程度等级补偿系数，乘以所对应保险责任的残疾赔偿限额赔偿。一至十级对应给付比例分别为保额的100%、90%、80%、70%、60%、50%、40%、30%、20%、10%。
      </p>
      <p>
        第十一条、被保险人购买安心呵护（基础版）后因预防接种异常反应在国家卫生行政管理部门医院等级分类中二级或二级以上公立医院的普通部，经投保所在地基本医疗保险、公费医疗、政府主办补充医疗和本公司在内的商业保险机构等任何其他途径支付后、需要被保险人个人负担的医疗费用（含自费），按90%的给付比例给付预防接种异常反应意外医疗费用保险金，若被保险人未从上述任何途径获得医疗费用补偿，给付比例为70%。
      </p>
      <p>
        被保险人购买高枕无忧（高端版）后因预防接种异常反应在国家卫生行政管理部门医院等级分类中二级或二级以上医疗机构（含特需门诊和国际部以及民营医疗机构），经投保所在地基本医疗保险、公费医疗、政府主办补充医疗和本公司在内的商业保险机构等任何其他途径支付后、需要被保险人个人负担的医疗费用（含自费），按90%的给付比例给付预防接种异常反应意外医疗费用保险金，若被保险人未从上述任何途径获得医疗费用补偿，给付比例为70%。
      </p>
      <p>第十二条、本产品约定的预防接种异常反应住院津贴保险金给付标准如下：</p>
      <p>
        被保险人保险期间内发生疑似预防接种异常反应，经卫生健康行政部门认可的机构按法定的调查诊断及鉴定程序认定为预防接种异常反应或不排除预防接种异常反应，并以此次事故为直接原因在约定的医院必须接受住院治疗的，本公司按下述公式给付预防接种异常反应住院津贴保险金：
      </p>
      <p>
        预防接种异常反应住院津贴保险金＝约定的该被保险人的预防接种异常反应住院津贴日额标准×实际住院治疗天数
      </p>
      <p>
        被保险人经认定发生预防接种异常反应或不排除预防接种异常反应而住院治疗至保险期届满治疗仍未结束的，本公司将按国家有关政策及属地预防接种异常反应补偿办法中相应补偿年限的的有关规定继续承担预防接种异常反应住院津贴保险金给付责任至住院结束。接种当地的预防接种异常反应补偿办法没有规定医疗费用补偿年限的，本公司承担预防接种异常反应住院津贴保险金的给付责任最长不超过本合同保险期间届满日后180天（含）。
      </p>
      <p>
        第十三条、本保险产品仅对被保险人在实施接种本合同保障针次的疫苗过程中或者实施接种后出现疑似预防接种异常反应，经卫生健康主管部门认可的机构按法定的调查诊断及鉴定程序认定为由本合同保障针次的疫苗导致预防接种异常反应或不排除预防接种异常反应承担保障责任。因下列情形之一引起的保险事故，本公司不承担给付保险金的责任：
      </p>
      <p>（1）《中华人民共和国保险法》规定不予支付的事项；</p>
      <p>
        （2）《中华人民共和国疫苗管理法》和接种当地的预防接种异常反应补偿政策规定不予支付的事项；
      </p>
      <p>
        （3）对于有接种禁忌而不能接种的被保险人，在医护人员提出医学建议后，被保险人或被保险人的监护人仍要求实施接种；
      </p>
      <p>（4）在中国境外接受预防接种或就医；</p>
      <p>（5）被保险人在追溯期之前接种的疫苗导致的预防接种异常反应。</p>
      <p>
        第十四条、如需办理保全问题或保险咨询，请您拨打人保健康官方客服电话95518或010-80882173。退保需要提供投保人有效身份证件及银行卡复印件。也可直接关注微信公众号“人保健康生活”，自助退保。
      </p>
      <p>
        第十五条、本保单不设犹豫期，保单生效后投保人要求解除本合同，本公司自收到解除合同申请书之日起30日内向投保人退还本合同的现金价值：现金价值金额＝年交保险费×（1-35%）×（1-m/n）。其中，m为已生效天数，n为保险期间的天数，经过日期不足一日的按一日计算。已发生保险事故的，现金价值为零。
      </p>
      <p>
        第十六条、本产品所有页面的文字描述均为展示作用，具体保障责任以购买成功后生成的电子保单为准，保险公司保留在法律规定范围内的解释权利。
      </p>
      <p>
        第十七条、请投保前仔细阅读保险条款，了解保险责任及责任免除，尤其对其中免除保险人责任的条款（包括但不限于责任免除、投保人被保险人义务、保险金申请与给付等），请充分理解并接受上述内容，同意以此作为订立保险合同的依据。如有未尽事宜以条款为准。
      </p>
      <p>
        第十八条、相关授权：如果您投保此保险，视为您及被保险人同意授权本公司出于提供保险服务的需要；可向与本公司具有必要合作关系的机构提供您或被保险人的信息（包括但不限于投保、承保、理赔、账户使用信息等）；本公司可通过知悉您或被保险人信息的机构查询您或被保险人有关的全部信息；本公司及与本公司具有必要合作关系的机构均可对上述信息进行合理使用。
      </p>
      <p>
        第十九条、信息安全：我们严格遵守现行的关于个人信息、数据及隐私保护的法律法规，采取充分的技术手段和制度管理，保护您提供给我们的个人信息、数据和隐私不受到非法的泄露或披露给未获授权的第三方。
      </p>
    </div>
    <div class="btn" v-if="$route.query.channel">
      <p @click="exemptionClause">我已阅读并同意以上内容</p>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  methods: {
    //跳转责任免除条款
    exemptionClause() {
      this.$router.push({
        path: "/exemptionClause",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.tip {
  width: 100%;
  height: 44px;
  background: #fff7f4;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    font-size: 14px;
    font-weight: 400;
    color: $primary-color;
    line-height: 21px;
  }
}
.msg {
  margin-top: 16px;
  padding: 0 20px;
  padding-bottom: 100px;
  p {
    font-size: 15px;
    font-weight: 400;
    color: #333333;
    line-height: 24px;
    text-align: justify;
  }
}
.btn {
  width: 100%;
  height: 90px;
  background: #fff;
  position: fixed;
  z-index: 1000;
  left: 0;
  bottom: 0;
  p {
    width: 234px;
    height: 45px;
    margin: 0 auto;
    background: $primary-color;
    border-radius: 23px;
    font-size: 15px;
    font-weight: bold;
    color: #ffffff;
    line-height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
  }
}
</style>